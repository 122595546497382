import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import Counter from '../../components/Counter'
import 'aos/dist/aos.css'

const Counters = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <Main>
      <Container>
        <Item data-aos="fade">
          <ItemTitle>Clientes</ItemTitle>
          <ItemSubtitle>
            <Counter number="31" duration="3" />
          </ItemSubtitle>
        </Item>
        <Item data-aos="fade">
          <ItemTitle>Productos</ItemTitle>
          <ItemSubtitle>
            <Counter number="15" duration="3" />
          </ItemSubtitle>
        </Item>
        <Item data-aos="fade">
          <ItemTitle>Paises</ItemTitle>
          <ItemSubtitle>
            <Counter number="57" duration="3" />
          </ItemSubtitle>
        </Item>
        <Item data-aos="fade">
          <ItemTitle>Rutas</ItemTitle>
          <ItemSubtitle>
            <Counter number="58" duration="3" />
          </ItemSubtitle>
        </Item>
      </Container>
    </Main>
  )
}

export default Counters

const Main = styled.div`
  width: 100vw;
  background-color: #f6be03;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 900px) 1fr;
  padding: 160px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-column: 1/2;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 0 40px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #000;
  position: relative;
  padding-left: 40px;
  &::before {
    content: '';
    height: 100%;
    width: 1px;
    background-color: #000;
    position: absolute;
    left: 0;
  }
`

const ItemTitle = styled.span`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  font-size: 15px;
  line-height: 1.4em;
  font-weight: 500;
  margin-bottom: 25px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
`
const ItemSubtitle = styled.span`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  font-size: 65px;
  line-height: 1.107em;
`
