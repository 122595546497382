import React, { useState, useEffect } from 'react'
import styles from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import { FaRegPaperPlane } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'
import logo from '../../assets/static/logo.svg'
import Side from '../../components/Side'
const Header = () => {
  const [togleBar, setTogle] = useState(false)
  const [navbar, setNavbar] = useState(false)
  const [side, setSide] = useState(false)

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  return (
    <>
      <Side show={side} onClick={() => setSide(false)} />
      <HeaderS navbar={navbar}>
        <TopBar>
          <IconsBar>
            <a href="tel:+522213544894">
              <FiPhoneCall />
              +52 221 354 4894
            </a>
            <a href="mailTo:ventas@grupoabrilto.com">
              <FaRegPaperPlane />
              ventas@grupoabrilto.com
            </a>
          </IconsBar>
          <IconsBar>
            <BiSupport />
            ventas@grupoabrilto.com
          </IconsBar>
        </TopBar>
        <Menu togleBar={togleBar}>
          <Img src={logo} alt="" />
          <Ul togleBar={togleBar}>
            <Li>
              <HashLink smooth onClick={() => setTogle(false)} to="/#home">
                Inicio
              </HashLink>
            </Li>
            <Li>
              <HashLink smooth onClick={() => setTogle(false)} to="/#nosotros">
                Nosotros
              </HashLink>
            </Li>

            <Li>
              <HashLink smooth onClick={() => setTogle(false)} to="/#services">
                Servicios
              </HashLink>
            </Li>
            <Li>
              <HashLink smooth onClick={() => setTogle(false)} to="/#products">
                Productos
              </HashLink>
            </Li>
            <Li>
              <HashLink smooth onClick={() => setTogle(false)} to="/#contact">
                Contacto
              </HashLink>
            </Li>
          </Ul>
          <Hamburguer onClick={() => setTogle(!togleBar)}>
            {togleBar ? <AiOutlineClose /> : <AiOutlineMenu />}
          </Hamburguer>
        </Menu>
        <HamburguesBlack onClick={() => setSide(true)}>
          <HamburgerContainer>
            <AiOutlineMenu />
          </HamburgerContainer>
        </HamburguesBlack>
      </HeaderS>
    </>
  )
}

export default Header

const HeaderS = styles.header`
display: grid;
grid-template-columns: 1fr 80px;
width: 100vw;
max-width: 100vw;
box-sazing: border-box;
background-color: #F6BE03;
border-bottom: 1px solid #000;
@media (max-width: 768px) {
  grid-template-columns: 1fr;
  width: 100vw;
  border-color: transparent;
  background-color: #fff;
}
`

const TopBar = styles.div`
  grid-column: 1 / 3;
  display: flex;
  background: #000;
  justify-content: space-between;
  color: #fff;
  font-family: 'Sarabun', sans-serif;
  font-weight: 100;
  font-size: 13px;
  padding: 0 40px;
  height: 33px;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 768px) {
    display: none;
  }
`

const IconsBar = styles.div`
display: flex;
 > a{
  color: #fff;
  margin-right: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
 }

 svg {
  margin-right: 10px;
  font-size: 16px;
 }
`

const Menu = styles.div`
grid-column: 1 / 2;
z-index: 11;
box-sizing: border-box;
display: flex;
justify-content: space-between;
align-items: center;
box-sazing: border-box;
max-width: 100vw;
padding: 0 40px;
width: 100%;
height: 100%;
max-height: 80px;
max-width: calc(100vw - 80px);
@media (max-width: 768px) {
  justify-content: space-between;
  width: 100vw;
  max-width: 100vw;
  padding-right: 0;
}
`
const Img = styles.img`
  height: 100%;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 60px;
  }
`

const Ul = styles.ul`
padding: 0;
margin: 0;
display: flex;
align-items: center;
list-style: none;
transition: all 1s ease-out;
height: 100%;
box-sizing: border-box;
@media (max-width: 768px) {
    background: #FFF;
    position: absolute;
    margin: 0;
    width: 100vw;
    height: 100vh;
    transition: all .5s ease-out;
    flex-direction: column;
    justify-content: center;
    boz-sizing: border-box;
    top: 0;
    left: 0;
    ${props =>
      props.togleBar === false
        ? 'transform: translateX(-100%);'
        : 'transform: translateX(0%);'}
  }
`

const Li = styles.li`
text-align: center;
padding: 0 25px;
position: relative;
display: flex;
align-items: center;
height: 100%;

  & a{
    font-family: 'Quicksand', sans-serif;
    font-weight: lighter;
    width: 100%;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 100;
    font-family: 'Sarabun', sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
   
  }
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transform: scale3d(0,1,1);
    transform-origin: left;
    transition: transform .2s;
   
  }
  &:hover{
    & a {
        color: #FFF;

    }
    &::before{
      background-color: #FFF;
     transform: scale3d(1,1,1);
     transition-timing-function: cubic-bezier(.4,0,.2,1);
     transition-duration: .3s;
    }
   }
  @media (max-width: 768px) {
    height: 40px;
  }
`

const Hamburguer = styles.div`
    font-family: 'Quicksand', sans-serif;
    font-weight: lighter;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 100;
    display: none;
    @media (max-width: 768px) {
      z-index: 12;
     display: flex;
     background: #f6be03;
     height: 80px;
     width: 80px;
      justify-content: center;
      align-items: center;
    }
`

const HamburguesBlack = styles.div`
  grid-column: 2 / 3;
  color: #FFF;
  width: 80px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`
const HamburgerContainer = styles.div`
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: #fff;
    background-color: #000;
    align-items: center;
    transition: color .2s ease-out;
    font-size: 1.5rem;
    &:hover{
      > svg {
        color: #000;
        z-index: 1;
      }
      color: #000;
      &::before{
        transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
        opacity: 1;
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1), opacity 0.3s;
      }
    }
    &::before{
      content: '';
      position: absolute;
      background: #F6BE03;
      width: 150%;
      height: 0;
      padding-bottom: 150%;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
      opacity: 0;
      transition: transform 0.3s cubic-bezier(0.1, 0, 0.3, 1), opacity 0.3s;
    }
    &::after{
      content: '';
      display: table;
      table-layout: fixed;
      clear: both;
    }
   
`
