import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import box from '../../assets/static/cargo-home-img-2-new.png'
import { AiOutlineCheck } from 'react-icons/ai'
// import client1 from '../../assets/static/Client-1.png'
import hover1 from '../../assets/static/mezcal.png'
import hover2 from '../../assets/static/muebles.jpeg'
import hover3 from '../../assets/static/plata.jpg'
import hover4 from '../../assets/static/talavera.jpeg'
import hover5 from '../../assets/static/cafe.jpeg'
import hover6 from '../../assets/static/vainilla.jpg'
import hover7 from '../../assets/static/berries.jpg'
import hover8 from '../../assets/static/chile.jpeg'
import hover9 from '../../assets/static/productos.jpg'
import hover10 from '../../assets/static/aceite.jpg'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="products">
      <Container>
        <Info>
          <Title data-aos="fade-right" data-aos-delay="100">
            Nuestros Productos
          </Title>
          <Text data-aos="fade-right" data-aos-delay="200">
            <AiOutlineCheck />
            <p>
              Nuestros productos se destacan por ser de la más alta calidad,
              además de tener los mejores costos del mercado.
            </p>
          </Text>
          <Text data-aos="fade-right" data-aos-delay="300">
            <AiOutlineCheck />
            <p>
              Nuestra experiencia, inventario e infraestructura, nos permiten
              llevar los productos, al mejor precio en el menor tiempo, a su
              destino.
            </p>
          </Text>
        </Info>
        <Image data-aos="fade-down" data-aos-delay="100">
          <img src={box} alt="" />
        </Image>
      </Container>
      <Grid data-aos="fade-up" data-aos-delay="400">
        <Card>
          <span>
            <Img src={hover9} alt="" />
          </span>
          <Palabra>
            Productos Naturales{' '}
            <span>Shampoo, acondicionador, jabón, crema...</span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover1} alt="" />
          </span>
          <Palabra>
            Mezcal y cremas de mezcal <span>Del estado de Oaxaca.</span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover2} alt="" />
          </span>
          <Palabra>
            Muebles de madera{' '}
            <span>Talla en hoja de oro, forja y craquelado</span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover8} alt="" />
          </span>
          <Palabra>Salsa de Habanero</Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover4} alt="" />
          </span>
          <Palabra>TALAVERA POBLANA</Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover3} alt="" />
          </span>
          <Palabra>
            Joyería fina de plata <span>del Taxco y Guerrero</span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover7} alt="" />
          </span>
          <Palabra>
            Zarzamora y Blue Berry <span>de Huauchinango, Puebla</span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover5} alt="" />
          </span>
          <Palabra>Café de Colombia</Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover6} alt="" />
          </span>
          <Palabra>
            Licor de Vainilla{' '}
            <span>
              extracto natural de vainilla y vainas de vainilla de Papantla,
              Veracruz
            </span>
          </Palabra>
        </Card>
        <Card>
          <span>
            <Img src={hover10} alt="" />
          </span>
          <Palabra>Aceite Vegetal</Palabra>
        </Card>
      </Grid>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: #13171a;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 10fr 11fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 40px;
    justify-content: center;
  }
`

const Info = styled.div`
  padding: 15% 15% 0% 0%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0;
    padding-top: 40px;
  }
`

const Title = styled.h2`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #fff;
  font-size: 45px;
  line-height: 1.155em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  padding-right: 30px;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
    padding-right: 0;
  }
`

const Text = styled.div`
display: flex;
align-items: center;
    padding-bottom: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 18px;
    color: #AEAEAE;
    line-height: 1.5em;
    padding-right: 45px;
    @media (max-width: 768px) {
        font-size: 16px;
        padding-right: 0;
    }
    > svg {
        margin-right: 10px;
        color: #f6be03;
        font-size: 20px;
    }
}
`

const Image = styled.div`
  img {
    width: 500px;
    object-fit: contain;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const Grid = styled.div`
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 120px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  border: 1px solid #545759;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 210px;
  cursor: pointer;
  > span {
    position: relative;
    grid-area: overlap;
    width: 100%;
    height: 100%;
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
    transition: -webkit-clip-path 0.6s cubic-bezier(0.57, 0.57, 0, 0.97);
    transition: clip-path 0.6s cubic-bezier(0.57, 0.57, 0, 0.97);
    transition: clip-path 0.6s cubic-bezier(0.57, 0.57, 0, 0.97),
      -webkit-clip-path 0.6s cubic-bezier(0.57, 0.57, 0, 0.97);
    z-index: 1;
  }

  &:hover {
    > span {
      clip-path: inset(0 0 0 0);
      -webkit-clip-path: inset(0 0 0 0);
    }
  }
`

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

// const Logo = styled.img`
//   object-fit: contain;
//   position: absolute;
//   z-index: 1;
// `

const Palabra = styled.h3`
  position: absolute;
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 25px;
  text-align: center;
  > span {
    font-size: 14px;
    display: block;
  }
`
