import React from 'react'
import styles from 'styled-components'
import { BsFacebook, BsInstagram } from 'react-icons/bs'

const Footer = () => {
  return (
    <Main>
      <Container>
        {/* <Rights>Powered by Zuse Technologies S.A. de C.V.</Rights> */}
        <Icons>
          <a href="https://www.facebook.com" target="blank">
            <BsFacebook />
          </a>
          <a href="https://www.instagram.com" target="blank">
            <BsInstagram />
          </a>
        </Icons>
      </Container>
    </Main>
  )
}

export default Footer

const Main = styles.footer`
width: 100vw;
display: grid;
grid-template-columns: 1fr minmax(375px, 900px) 1fr;
position: relative;
background-color: #dadada;
`

const Container = styles.div`
    grid-column: 2/3;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-top: 1px solid;
    border-color: #1b1b1b59;
    position: relative;
`

// const Rights = styles.p`
//     line-height: 1.7;
//     margin: 20px 0;
//     font-size: 1rem;
// `

const Icons = styles.div`
  position: absolute;
  bottom: 50%;
  right: 0%;
  transform: translateY(50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > a{
      color: #000;
      font-size: 1.2rem;
      margin: .5rem;
    }

    @media (max-width: 768px) {
    position: relative;
  transform: translateY(100%);
  margin-right: 20px;

    }
`
