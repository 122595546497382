import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`
  body {
    transition: all 0.2s ease-in,;
    font-family: 'Roboto', sans-serif;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 150px !important;
    height: 75px !important;
    margin: auto 0 !important;
    object-fit: contain !important;
  }
  
`

export const lightTheme = {
  body: '#fff',
  text: '#000',
}
export const darkTheme = {
  body: '#fff',
  text: '#000',
}
