import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import banner from '../../assets/static/banner.png'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <InicioS id="home">
      <Image>
        <img src={banner} alt="" />
      </Image>
      <Title data-aos="fade-left" data-aos-delay="200">
        Los expertos en comercio
      </Title>
    </InicioS>
  )
}

export default Inicio

const InicioS = styled.div`
  height: calc(100vh - 80px);
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: #f6be03;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-top: 120px;
  }
`

const Image = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    height: 40vh;
    object-fit: contain;
    @media (max-width: 768px) {
      max-width: 100vw;
    }
  }
`

const Title = styled.h1`
  position: absolute;
  bottom: 100px;
  left: 10%;
  width: 100%;
  font-family: Sarabun;
  font-size: 120px;
  font-weight: 700;
  line-height: 1.1;
  max-width: 1200px;
  margin: 0;
  margin-top: 50px;
  color: #000;
  text-align: left;
  line-height: 72px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 65px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`
