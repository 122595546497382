import { createGlobalStyle } from "styled-components";

import FSMeridianBold from "./FSMeridian-Bold.woff";
import FSMeridianBold2 from "./FSMeridian-Bold.woff2";
import FSMeridianLight from "./FSMeridian-Light.woff";
import FSMeridianLight2 from "./FSMeridian-Light.woff2";
import FSMeridianMedium from "./FSMeridian-Medium.woff";
import FSMeridianMedium2 from "./FSMeridian-Medium.woff2";
import FSMeridianRegular from "./FSMeridian-Regular.woff";
import FSMeridianRegular2 from "./FSMeridian-Regular.woff2";
import FSMeridianThin from "./FSMeridian-Thin.woff";
import FSMeridianThin2 from "./FSMeridian-Thin.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: 'Meridian';
        src: local('Meridian'), local('Meridian'),
        url(${FSMeridianRegular}) format('woff2'),
        url(${FSMeridianRegular2}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Meridian';
        src: local('Meridian'), local('Meridian'),
        url(${FSMeridianLight}) format('woff2'),
        url(${FSMeridianLight2}) format('woff');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'Meridian';
        src: local('Meridian'), local('Meridian'),
        url(${FSMeridianMedium}) format('woff2'),
        url(${FSMeridianMedium2}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Meridian';
        src: local('Meridian'), local('Meridian'),
        url(${FSMeridianBold}) format('woff2'),
        url(${FSMeridianBold2}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Meridian';
        src: local('Meridian'), local('Meridian'),
        url(${FSMeridianThin}) format('woff2'),
        url(${FSMeridianThin2}) format('woff');
        font-weight: 100;
        font-style: normal;
    }
`;
