import React from 'react'
import Inicio from './Home'
import Nosotros from './Nosotros'
import Hero from './Hero'
import Review from './Review'
import Counters from './Counters'
import Services from './Services'
import Hero2 from './Hero2'
import Our from './Our'
import Contact from './Contact'

const Home = () => {
  return (
    <>
      <Inicio />
      <Nosotros />
      <Hero />
      <Review />
      <Counters />
      <Services />
      <Hero2 />
      <Our />
      <Contact />
    </>
  )
}

export default Home
