import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import box from '../../assets/static/box.png'
import { AiOutlinePlus } from 'react-icons/ai'

const Inicio = () => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="nosotros">
      <Container>
        <Info>
          <Title  data-aos="fade-left" data-aos-delay="100">¿Quiénes Somos?</Title>
          <Text  data-aos="fade-left" data-aos-delay="200">
            GRUPO ABRILTO S.A. de C.V. se constituye el 12 de Abril del año
            2000, con el propósito de comerciar, distribuir y vender cualquier
            tipo de bien susceptible de compra venta (bienes de consumo
            perecederos, no perecederos, muebles e inmuebles), con el objeto de
            crear una empresa comercializadora y proveedora de productos de
            importación y exportación para satisfacer las necesidades del
            mercado y demandas de consumo de la población en México y el
            Exterior.
          </Text>
          {isOpen ? (
            <Text>
              Nuestro esfuerzo, está dirigido a la atención personalizada para
              que nuestros socios de negocios logren un crecimiento sólido y
              permanente haciéndonos partícipes del mismo, al tiempo de
              satisfacer las necesidades del mercado, involucrándose en los
              procesos de sus clientes para asesorarlos en la selección y uso de
              los productos, ofreciéndoles la oportunidad de adquirir en un
              mismo lugar una diversidad de bienes, buscando siempre la
              excelencia en el servicio a precios competitivos, estando siempre
              atentos a oportunidades de integración y desarrollo de nuevos
              negocios Todo el equipo de GRUPO ABRILTO está comprometido a
              laborar bajo un esquema en el que se ofrezcan productos y
              servicios de calidad con un continuo valor agregado, siendo éste
              el objetivo eje de nuestro desarrollo personal y empresarial.
              GRUPO ABRILTO cuenta con canales de distribución y venta por la
              Republica Mexicana,  los Estados Unidos de América, principalmente
              en noreste de la Unión Americana y Europa Occidental.
            </Text>
          ) : null}
          <MoreContainer data-aos="fade-left" data-aos-delay="300">
            <More onClick={() => setIsOpen(!isOpen)}>
              <Icon>
                <AiOutlinePlus />
              </Icon>
              <MoreText>
                Ver más sobre <br /> nosotros
              </MoreText>
            </More>
          </MoreContainer>
        </Info>
        <Image data-aos="fade-down" data-aos-delay="400">
          <img src={box} alt="" />
        </Image>
      </Container>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: #dadada;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  padding-top: 180px;
  padding-bottom: 160px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 120px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 40px;
    justify-content: center;
  }
`

const Info = styled.div`
  padding-right: 40px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  font-size: 45px;
  line-height: 1.155em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Text = styled.p`
    border-bottom: 1px solid;
    border-color: #1B1B1B59;
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-family: Sarabun, sans-serif;
    font-size: 18px;
    color: #5b5b5b;
    line-height: 1.5em;
    @media (max-width: 768px) {
        font-size: 16px;
    }
}
`

const Image = styled.div`
  img {
    height: 40vh;
    object-fit: contain;
    @media (max-width: 768px) {
      max-height: 300px;
      max-width: 100%;
    }
  }
`

const Icon = styled.span`
  align-items: center;
  background-color: #f6be03;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 54px;
  justify-content: center;
  margin: 0;
  width: 54px;
  svg {
    transform: rotate(0);
    transform-origin: center;
    transition: transform 0.2s ease-out;
  }
`

const More = styled.div`
  display: flex;
  cursor: pointer;
  :hover ${Icon} > svg {
    transform: rotate(180deg);
  }
`

const MoreText = styled.span`
  font-size: 15px;
  line-height: 1.133em;
  margin-left: 19px;
  display: flex;
  align-items: center;
  font-weight: 700;
`

const MoreContainer = styled.div`
  display: flex;
`
