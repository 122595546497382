import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import box from '../../assets/static/cargo-home-img-3.jpeg'
import { AiOutlinePlus } from 'react-icons/ai'

const Inicio = () => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="services">
      <Container>
        <Info>
          <Title data-aos="fade-left" data-aos-delay="100">
            Especialistas en soluciones logísticas
          </Title>
          <Text data-aos="fade-left" data-aos-delay="200">
            Asesoramos y gestionamos procesos logísticos que garanticen la
            cadena de suministro de nuestros clientes y nuestros productos.
          </Text>
          {isOpen ? (
            <Text>
              Realizamos Importaciones y exportaciones a todo el mundo. Contamos
              con más de 50 rutas y destinos alrededor del mundo que nos
              permiten ser los primeros en entregar a nuestros
            </Text>
          ) : null}
          <MoreContainer data-aos="fade-left" data-aos-delay="300">
            <More onClick={() => setIsOpen(!isOpen)}>
              <Icon>
                <AiOutlinePlus />
              </Icon>
              <MoreText>
                Ver más sobre
                <br />
                nuestros servicios
              </MoreText>
            </More>
          </MoreContainer>
        </Info>
        <Image data-aos="fade-right" data-aos-delay="400">
          <img src={box} alt="" />
        </Image>
      </Container>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: #dadada;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  padding-top: 180px;
  padding-bottom: 160px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 120px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 40px;
    justify-content: center;
  }
`

const Info = styled.div`
  box-sizing: border-box;
  border-right: 1px solid;
  border-color: #1b1b1b59;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    padding: 0;
    border-color: transparent;
  }
`

const Title = styled.h2`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  font-size: 45px;
  line-height: 1.155em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  padding-right: 30px;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
    padding-right: 0;
  }
`

const Text = styled.p`
    border-bottom: 1px solid;
    border-color: #1B1B1B59;
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-family: Sarabun, sans-serif;
    font-size: 18px;
    color: #5b5b5b;
    line-height: 1.5em;
    padding-right: 45px;
    @media (max-width: 768px) {
        font-size: 16px;
        padding-right: 0;
    }
}
`

const Image = styled.div`
  margin-left: 60px;
  img {
    height: 40vh;
    object-fit: contain;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const Icon = styled.span`
  align-items: center;
  background-color: #f6be03;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 54px;
  justify-content: center;
  margin: 0;
  width: 54px;
  svg {
    transform: rotate(0);
    transform-origin: center;
    transition: transform 0.2s ease-out;
  }
`

const More = styled.div`
  display: flex;
  cursor: pointer;
  :hover ${Icon} > svg {
    transform: rotate(180deg);
  }
`

const MoreText = styled.span`
  font-size: 15px;
  line-height: 1.133em;
  margin-left: 19px;
  display: flex;
  align-items: center;
  font-weight: 700;
`

const MoreContainer = styled.div`
  display: flex;
`
