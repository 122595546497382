import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import bg from '../../assets/static/bg.jpg'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section>
      <Container>
        <div></div>
        <Card>
          <Svg data-aos="fade-down" data-aos-delay="100">
            <svg
              class="qodef-svg--quote"
              xmlns="http://www.w3.org/2000/svg"
              width="75.172"
              height="62.462"
              viewBox="0 0 75.172 62.462"
            >
              <g transform="translate(-762.938 -1079.129)">
                <g transform="translate(763.438 1079.634)">
                  <g>
                    <path
                      d="M808.7,1141.091H837.61v-27.657H824.424a1.159,1.159,0,0,1-1.149-1.17v-2.83c0-10.565,3.98-16.034,11.832-16.261v-13.538c-10.007.106-17.7,3.274-22.865,9.421-5.278,6.275-7.954,14.69-7.954,25.01A89.185,89.185,0,0,0,808.7,1141.091Zm-40.631,0h28.921v-27.657H783.574a1.159,1.159,0,0,1-1.15-1.17v-2.83c0-10.57,4.056-16.039,12.06-16.262v-13.537c-10.01.105-17.739,3.275-22.984,9.424-5.351,6.277-8.063,14.691-8.063,25.008A84.046,84.046,0,0,0,768.065,1141.091Z"
                      transform="translate(-763.438 -1079.634)"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </Svg>
          <Info>
            <Title data-aos="fade-down" data-aos-delay="200">
              Nos enfocamos en dar el mejor servicio a nuestros clientes, nos
              interesamos y nos involucramos en sus procesos para poder ofrecer
              productos y servicios a la medida
            </Title>
          </Info>
          <Profile data-aos="fade-down" data-aos-delay="300">
            <Image src="https://picsum.photos/200" alt="" />
            <div>
              <Subtitle>COO</Subtitle>
              <Name>Abril M.</Name>
            </div>
          </Profile>
        </Card>
      </Container>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  padding: 120px 0;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
    url(${bg});
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 10fr 11fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 40px;
  }
`

const Card = styled.div`
  width: 100%;
`

const Svg = styled.div`
  width: 70px;
  display: block;
  z-index: 1;
  svg {
    stroke: #424242;
    stroke-width: 2px;
    fill: transparent;
  }
`

const Info = styled.div`
  color: #fff;
  width: 100%;
  padding: 40px 0;
`

const Title = styled.h2`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  font-size: 45px;
  line-height: 1.155em;
  margin: 5px 0;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Profile = styled.div`
  display: flex;
`

const Name = styled.h3`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #fff;
  font-size: 32px;
  line-height: 1.25em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  margin: 0;
`

const Subtitle = styled.h6`
  color: #777777;
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  font-size: 15px;
  line-height: 1.4em;
  font-weight: 500;
  margin: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
`

const Image = styled.img`
  postion: absolute;
  object-fit: cover;
  object-position: center;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin-right: 30px;
`
