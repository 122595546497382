import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import logo from '../../assets/static/logo.svg'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="contact">
      <Container>
        <Image data-aos="fade-down" data-aos-delay="100">
          <img src={logo} alt="" />
        </Image>
        <Info data-aos="fade-left" data-aos-delay="200">
          <Row>
            <div>
              Soporte 24/7
              <Phone>
                <a href="tel:+522213544894">+52 221 354 4894</a>
              </Phone>
            </div>
            <div>
              <div>Nos puedes encontrar en:</div>
              <span>15 Sur, San Cristobal Tepontla, 72764 Cholula, Pue.</span>
            </div>
          </Row>
          <div>Ponte en contacto con nosotros:</div>
          <a href="mailto:ventas@grupoabrilto.com">
            ventas@grupoabrilto.com
          </a>
        </Info>
      </Container>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: #dadada;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  padding-top: 140px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 80px;
  }
`

const Container = styled.div`
  grid-column: 2 / 3;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 40px;
    justify-content: center;
  }
`

const Info = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  padding: 40px 60px;
  color: #979797;
  font-size: 1rem;
  a {
    text-decoration: none;
    color: #000;
    font-size: 22px;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    font-size: 22px;
  }
  div {
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    padding: 35px;
  }
`

const Phone = styled.div`
  a {
    text-decoration: none;
    font-family: Sarabun, sans-serif;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #1b1b1b;
    font-size: 45px;
    line-height: 1.155em;
    @media (max-width: 768px) {
      font-size: 37px;
    }
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Image = styled.div`
  margin-right: 100px;
  img {
    height: 100px;
    object-fit: contain;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
`
