import React from 'react'
import styles from 'styled-components'
import logo from '../../assets/static/logo_white.svg'

const Slide = props => {
  const { show, onClick } = props

  return (
    <>
      <SlideStyled show={show} onClick={onClick}></SlideStyled>
      <Container show={show}>
        <Img src={logo} alt="" />
        <P>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta
          provident aspernatur consequatur saepe aliquam
        </P>
        <Info>
          <div>Soporte 24/7</div>
          <Phone>
            <a href="tel:+522213544894">+52 221 354 4894</a>
          </Phone>

          <Row>
            <div>
              <div>Nos puedes encontrar en:</div>
              <span>15 Sur, San Cristobal Tepontla, 72764 Cholula, Pue.</span>
            </div>

            <div>
              <div>Ponte en contacto con nosotros:</div>
              <a href="mailto:ventas@grupoabrilto.com">ventas@grupoabrilto.com</a>
            </div>
          </Row>
        </Info>
      </Container>
    </>
  )
}

export default Slide

const SlideStyled = styles.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => (props.show ? '99' : '-1')};
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    transition: all .6s cubic-bezier(.77,0,.175,1);
    background-color: ${props =>
      props.show ? 'rgba(0,0,0,0.2)' : 'transparent'};
      @media (max-width: 768px) {
        display: none;
      }
}
`

const Container = styles.div`
    position:fixed;
    height: 100vh;
    width: 500px;
    color: #fff;
    transition: all .6s cubic-bezier(.77,0,.175,1);
    background-color: #1b1b1b;
    min-height: 100%;
    padding: 148px 70px 35px 80px;
    box-sizing: border-box;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
    ${props => (props.show ? 'right: 0;' : 'right: -500px;')}
z-index: 999;
@media (max-width: 768px) {
    display: none;
  }
`

const Img = styles.img`
    height: 100px;
`

const P = styles.p`
color: #979797;
font-family: Sarabun, sans-serif;
font-size: 18px;
line-height: 1.4;
`

const Info = styles.div`
    margin-top: 80px;
    color: #979797;
    font-size: .9rem;
    a {
        color: #fff;
        text-decoration: none;
    }

    span {
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
    }
    div {
        margin-bottom: 20px;
    }
`

const Phone = styles.div`
a{
    color: #fff;

        text-decoration: none;
        font-weight: bold;
        font-size: 1.8rem;
    }

`

const Row = styles.div`
    display: flex;
    margin-top: 30px;
`
