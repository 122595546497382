import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import img1 from '../../assets/static/cargo-home-banner-1.jpeg'
import img2 from '../../assets/static/cargo-home-banner-2.jpeg'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section>
      <Card data-aos="fade-right" data-aos-delay="100">
        <Image src={img1} alt="" />
        <Info>
          <Subtitle>Nuestros Servicios</Subtitle>
          <Title>Venta y comercialización</Title>
        </Info>
      </Card>
      <Card data-aos="fade-left" data-aos-delay="100">
        <Image src={img2} alt="" />
        <Info>
          <Subtitle>Nuestros Servicios</Subtitle>
          <Title>Importación y exportación</Title>
        </Info>
      </Card>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  overflow: hidden;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Info = styled.div`
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`

const Title = styled.h2`
  font-family: Sarabun, sans-serif;
  font-weight: 700;
  letter-spacing: -0.01em;
  font-size: 45px;
  line-height: 1.155em;
  margin: 5px 0;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Subtitle = styled.h3`
  margin: 0;
  font-family: Sarabun, sans-serif;
  font-weight: 200;
`

const Image = styled.img`
  postion: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
