import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import img1 from '../../assets/static/cargo-home-video-button-1024x427.jpeg'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="hero2">
      <Image src={img1} alt="" />
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;
  height: 80vh;
  @media (max-width: 768px) {
    height: auto;
    background-color: #13171a;
  }
`

const Image = styled.img`
  postion: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
